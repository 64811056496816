.container-footer {
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 20;
    width: 100%;
}

.footer__copy {
    // color: var(--black);
    color: var(--bg1);
    text-align: right;
    line-height: 30px;
    height: 30px;
    margin-right: 15px;
    font-family: "Amatic SC", cursive;
}