@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");

.contact {
  padding-top: 50px;
  min-height: 100vh;
}

.contact__form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title-container {
  margin-bottom: 20px;
}

.contact__form-1 {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 35px;
  background: var(--bg1);

  @media (max-width: 767px) {
    padding: 25px;
  }

  h6 {
    font-size: 32px;
    font-weight: 600;
    color: var(--black);
    margin: 0 0 5px;
    font-family: "Amatic SC";
    // text-align: center;
  }

  .form-label {
    font-size: 25px;
    font-family: "Amatic SC";
  }

  form {
    padding: 10px;
  }

  .form-control {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    padding: 0.575rem 0.75rem;
    background-color: var(--bg);
    font-family: "Amatic SC";
  }

  .ok {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

    &:focus {
      box-shadow: 0px 0px 20px 0px #f4daa6;
    }
  }

  .error {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgb(214, 158, 158);

    &:focus {
      box-shadow: 0px 0px 20px 0px #f4daa6;
    }
  }

  .form-group {
    span {
      padding: 10px 5px 0 5px;
      color: rgb(210, 47, 47);
    }
  }
}

.form__button {
  a {
    text-decoration: none;
  }

  Button {
    z-index: 2;
  }
}

.contact__infos {
  padding: 60px 10px 30px 10px;
  font-size: 30px;

  a {
    color: #333;
    margin: 10px;
    padding: 10px;

    @media screen and (max-width: 460px) {
      margin: 0px;
    }
  }
}

.contact__image img {
  width: 250px;
}

.message__success {
  span {
    padding: 7px 15px;
    border-radius: 10px;
    color: rgb(52, 171, 104);
    background: rgba(203, 255, 226, 0.4);
  }
}

.message__error {
  span {
    padding: 7px 15px;
    border-radius: 10px;
    color: rgb(139, 41, 41);
    background: rgba(255, 201, 201, 0.4);
  }
}

.message__empty {
  span {
    padding: 7px 15px;
    border-radius: 10px;
    color: rgb(110, 100, 39);
    background: #f4daa6;
  }
}

.space {
  padding-top: 60px;
  height: 30px;
  width: 100%;
}