@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Taviraj:wght@100&display=swap');

:root {
    --font-base: "DM Sans", sans-serif;
    --bg: rgb(250, 250, 250);
    /* --bg: rgb(250,250,250); */
    --bg2: rgb(254, 247, 233);
    /* --bg2: rgb(254, 247, 233); */
    --bg1: #e4e4e482;
    --primary: #e9a622;
    --secondary: #e9a622;
    --yellow-hover: rgb(235, 173, 50);
    --black: #333;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    color: var(--black);
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: grey;
    transition: all 0.5s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--black);
    transition: all 0.5s ease;
}

.bar {
    font-weight: 800;
    font-size: 60px;
    padding: 0 5px;
}

.tags {
    color: var(--primary);
    font-family: "Amatic SC", cursive;
}

img {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}