@import url("https://fonts.googleapis.com/css2?family=Taviraj:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");

.navbar {
  width: 100%;
  position: fixed !important;
  background-color: rgba(250, 250, 250, 0.4);
  // background-color: var(--bg);
  backdrop-filter: blur(30px);
  // height: 100px;
  color: var(--black);
  min-width: 50px;
  font-family: "Copperplate", serif;
  z-index: 10;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  z-index: 0;
  backdrop-filter: blur(30px);
}

.navbar-collapse {
  transition: 0.5s ease;
  margin: 0 0px 0 0;
}

.navbar-nav {
  a {
    color: var(--black);
    transition: 0.25s ease;

    &:hover,
    &:focus {
      color: var(--secondary);
    }
  }
}


.navbar-nav-btn {
  display: none;

  @media screen and (max-width: 420px) {
    display: block;
  }
}

.navbar-btn {
  // display: none;
  position: absolute;
  right: 10px;
  top: 22px;

  @media screen and (max-width: 991.5px) {
    display: block;
    margin-right: 70px;
  }

  @media screen and (max-width: 420px) {
    display: none;
  }
}





.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--secondary);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .4s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background: var(--secondary);
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;

  img {
    border-radius: 100%;
    transition: 0.8s ease;
    height: 70%;
  }

  h1 {
    font-family: "DJ Gross", sans-serif;
    font-weight: 500;
    transition: 1s ease;
    margin-top: 10px;

    a {
      color: var(--black);
    }
  }
}

.social-container-collapsed {
  width: 250px;
  height: 25px;
}

.social__links {
  a {
    font-size: large;
    color: var(--black);
    margin: 0 10px;
  }
}

.nav-item-selected {
  a {
    color: var(--secondary);
  }
}