.section__heading {
  font-size: 50px;
  color: var(--black);
  font-family: "Copperplate", serif;
  transition: 0.8s ease;
  word-wrap: break-word;

  @media screen and (max-width: "392px") {
    font-size: 49px;
  }

  @media screen and (max-width: "344px") {
    font-size: 42px;
  }

  @media screen and (max-width: "274px") {
    font-size: 37px;
  }

  @media screen and (max-width: "254px") {
    font-size: 32px;
  }
}