.about {
  transition: 1s ease;
  padding-top: 80px;
  padding-bottom: 10px;
  min-height: 100vh;
}

.container-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.picture-container {
  padding: 30px 20px;
  min-width: 50%;

  img {
    width: 100%;
    min-height: 600px;
    // min-width: 600px;
    object-fit: cover;
  }
}

.text-container {
  padding: 30px 50px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: var(--black);
    margin: 0 0 5px;
    font-family: "Amatic SC";
    // font-family: "Book Antiqua", serif;
    margin-top: 20px;

    @media screen and (max-width: 992px) {
      margin-top: 0px;
    }
  }

  h2 {
    font-size: 30px;
    color: var(--black);
    margin: 0 0 5px;
    // font-family: "Big Clacson", serif;
    font-family: 'Amatic SC', serif;
    // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', sans-serif;
  }
}

.empty-line {
  margin-top: 40px;
}