@import url("https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Taviraj:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Square+Peg&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bad+Script&display=swap");

#background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.img-background {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.header {
  position: relative;
  padding-top: 25vh;
  transition: 0.8s ease;
  min-height: 100vh;

  @media screen and (max-width: 992px) {
    padding-top: 20vh;
    text-align: center;
  }

  @media screen and (max-width: 820px) {
    padding-top: 20vh;
  }

  @media screen and (max-width: 445px) {
    padding-top: 20vh;
  }

  @media screen and (max-width: 392px) {
    padding-top: 20vh;
  }

  @media screen and (max-width: 340px) {
    padding-top: 20vh;
  }

  @media screen and (max-width: 300px) {
    padding-top: 20vh;
  }
}

.header__flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;

  img {
    width: 75%;
  }

  @media (max-width: 992px) {
    margin-top: 60px;
  }
}

.header__hello {
  font-size: 30px;
  font-weight: bold;
  font-family: "Copperplate", sans-serif;

  @media screen and (max-width: "352px") {
    font-size: 25px;
    transition: 0.8s ease;
  }

  @media screen and (max-width: "274px") {
    font-size: 20px;
    transition: 0.8s ease;
  }
}

.header__intro {
  font-size: 40px;
  font-family: "Cormorant SC", sans-serif;
  transition: 0.8s ease;
  color: var(--primary);

  @media screen and (max-width: "772px") {
    font-size: 75px;
  }

  @media screen and (max-width: "545px") {
    font-size: 72px;
  }

  @media screen and (max-width: "508px") {
    font-size: 62px;
  }

  @media screen and (max-width: "445px") {
    font-size: 55px;
  }

  @media screen and (max-width: "392px") {
    font-size: 49px;
  }

  @media screen and (max-width: "344px") {
    font-size: 42px;
  }

  @media screen and (max-width: "274px") {
    font-size: 37px;
  }
}

.header__list {
  list-style-type: none;
  font-size: 50px;
  font-family: "DJ Gross", cursive;
  padding-bottom: 20px;
  transition: 0.8s ease;

  @media screen and (max-width: "772px") {
    font-size: 55px;
  }

  @media screen and (max-width: "545px") {
    font-size: 50px;
  }

  @media screen and (max-width: "508px") {
    font-size: 45px;
  }

  @media screen and (max-width: "445px") {
    font-size: 40px;
  }

  @media screen and (max-width: "392px") {
    font-size: 35px;
  }

  @media screen and (max-width: "344px") {
    font-size: 30px;
  }

  @media screen and (max-width: "274px") {
    font-size: 25px;
  }
}